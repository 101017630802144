.BottomSheet {
    backdrop-filter: brightness(80%);

    width: 100vw;
    height: 100vh;
    height: 100svh;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    display: flex;
    align-items: flex-end;
    justify-content: center;
}
