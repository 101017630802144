#search-tracks {
    form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;

        .main {
            display: flex;
            width: 100%;
            flex-direction: column;
        }

        .additional {
            display: flex;
            width: 100%;

            .share {
                flex: 1;
            }
        }
    }

    .results {
        margin-top: 1rem;
        margin-bottom: 6rem;
        .search-result {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: background-color 100ms ease;
            gap: 1rem;
            max-width: calc(100vw - 2 * 1rem);

            &:not(:last-child) {
                border-bottom: 1px solid black;
            }

            &:hover {
                background-color: lightgray;
            }

            button {
                cursor: pointer;
                padding: 0rem;
                border-radius: 50%;

                img {
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                }
            }
        }
    }
}
