.join-room {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    form {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
