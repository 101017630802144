@import "../../assets/styles/globals.scss";

.Navigation {
    height: $header-height;
    display: flex;
    background-color: $primary;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;

    a,
    .link {
        cursor: pointer;
        color: black;
        text-decoration: none;
        padding: 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 250ms ease;
    }

    .logo {
        font-size: 2rem;
        font-weight: bold;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $header-height;
        
        font-weight: bolder;

        a:hover,
        .link:hover {
            background-color: $primary-darker;
        }

        .active {
            background-color: $primary-darker;
        }
    }

    &.smaller {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;

        .burger {
            cursor: pointer;
            min-width: 50px;
            min-height: 50px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            gap: 0.5rem;

            .stripe {
                height: 4px;
                width: 100%;
                background-color: black;
                transition-property: transform opacity;
                transition-duration: 100ms;
                transition-timing-function: ease;
            }

            &.collapsed {
                .stripe:nth-child(1) {
                    transform: translateY(0.75rem) rotate(45deg);
                }
                .stripe:nth-child(2) {
                    opacity: 0;
                }
                .stripe:nth-child(3) {
                    transform: translateY(-0.75rem) rotate(-45deg);
                }
            }
        }

        nav {
            z-index: 100;
            border-top: 3px solid black;
            background-color: $primary;
            position: fixed;
            top: $header-height;
            left: 0;
            width: 100vw;
            height: calc(100vh - $header-height);
            height: calc(100svh - $header-height);
            flex-direction: column;
            align-items: unset;
            transition: left 200ms ease;
            font-size: 2rem;

            &.hidden {
                left: 100vw;
            }
        }
    }
}
