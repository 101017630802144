.TrackDetails {
    .details-card {
        background-color: white;
        margin: 1rem;
        padding-bottom: 1rem;
        max-width: 500px;

        .infos {
            margin: 1rem;

            .name {
                font-weight: bold;
            }

            .artists {
                color: gray;
            }
        }
    }
}
