.TrackInfoItem {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    gap: 1rem;

    overflow: hidden;

    img {
        max-width: 75px;
        max-height: 75px;
        border: 1px solid black;
    }

    .title-artists {
        max-width: calc(100% - 5.5rem);

        .title {
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .artists {
            color: gray;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
