.FullpageOverlay {
    background-color: white;

    width: 100vw;
    height: 100vh;
    height: 100svh;

    overflow-y: scroll;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    padding: 1rem;
}
