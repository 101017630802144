@import "./assets/styles/globals.scss";

.App {
    text-align: center;
    font-family: sans-serif;

    main {
        padding: 1rem;
        padding-top: calc($header-height + 1rem);
        min-height: calc(100vh - $header-height);
        min-height: calc(100svh - $header-height);
    }
}
