@mixin input() {
    cursor: pointer;
    border: 3px solid $primary;
    outline: none;
    border-radius: 0.1rem;
    padding: 1rem;
    background-color: white;
    transition: background-color 150ms ease-in-out;
    font-weight: bold;

    &:hover {
        background-color: $primary;
    }
}
