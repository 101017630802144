@import "../../assets/styles/globals.scss";

.PlaybackState {
    background-color: $primary;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;

    padding: 1rem;
    z-index: 98;

    &.paused {
        filter: grayscale(80%) brightness(80%);
    }

    .current-track {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.5rem;
        gap: 0.5rem;

        .infos {
            display: flex;
            gap: 1rem;

            .track {
                height: 8ch;
                overflow: hidden;
                text-overflow: ellipsis;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        img {
            max-width: 60px;
            object-fit: contain;
        }

        .progress {
            position: absolute;
            bottom: 0;
            border: 2px solid black;
        }

        .actions {
            button {
                padding: 0;
                border-radius: 50%;

                img {
                    max-width: 60px;
                }
            }
        }
    }
}
