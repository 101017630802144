.QueueList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;

    .queue {
        .track {
            &:not(:last-child) {
                border-bottom: 1px solid black;
            }
        }
    }

    .back {
        position: fixed;
        bottom: 1rem;
        right: 1rem
    }
}
