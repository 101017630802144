@mixin button() {
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 0.1rem;
    padding: 1rem;
    background-color: $primary;
    transition: background-color 150ms ease-in-out;
    font-weight: bold;

    &:hover {
        background-color: $primary-darker;
    }

    &.warn {
        background-color: $warn;

        &:hover {
            background-color: $warn-darker;
        }
    }
}
