#loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100svh;
    width: 100vw;
    z-index: 10000000;
    backdrop-filter: brightness(80%);
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
        width: 100px;
        height: 100px;
        border: 10px solid white;
        border-color: #fff transparent #fff transparent;
        border-radius: 50%;
        animation: rotate 1.2s infinite linear;

        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }
}
